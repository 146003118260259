import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";

@Component({
  components: {
    DfCardAttachment: () => Utils.externalComponent2("df-card-attachment"),
  },
})
export default class DfSectionAttachmentsComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Array, default: [] })
  attachments: Array<any>;

  get files(): Array<any> {
    return this.attachments.filter((attachment) => !attachment.image);
  }
}
